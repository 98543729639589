@import "bootstrap/dist/css/bootstrap.min";

@import "./theme";

/* moul-regular - khmer */
@font-face {
  font-family: "Kh Ang MoulHand 2";
  font-style: normal;
  src: local("Kh-Ang-MoulHand2"), url(../fonts/Kh-Ang-MoulHand2.ttf) format("truetype");
  /* Super Modern Browsers */
  unicode-range: "U + 1780-17FF", "U + 200B-200C", "U + 25CC";
}

@font-face {
  font-family: "Content S1";
  font-style: normal;
  font-weight: 400;
  src: local("Content-S1"), url(../fonts/Content-S1.ttf) format("truetype");
  /* Super Modern Browsers */
  unicode-range: "U + 1780-17FF", "U + 200B-200C", "U + 25CC";
}

@font-face {
  font-family: 'Metropolis';
  src: url(../fonts/Metropolis-Regular.otf),
    url(../fonts/Metropolis-Bold.otf),
    url(../fonts/Metropolis-ExtraBold.otf),
    url(../fonts/Metropolis-RegularItalic.otf);
  /* Super Modern Browsers */
  unicode-range: "U + 1780-17FF", "U + 200B-200C", "U + 25CC";
}

.text-blue {
  color: #20409a !important;
}

.qr-code {
  table {
    border-width: 0;
    border-style: none;
    border-color: #0000ff;
    border-collapse: collapse;
    width: 100px;
    height: 100px;
  }

  td {
    border-left: solid 2px #000;
    padding: 0;
    margin: 0;
    width: 0px;
    height: 2px;
  }

  td.black {
    border-color: #000;
  }

  td.white {
    border-color: #fff;
  }
}

a[disabled='disabled'] {
  cursor: not-allowed;
}

body#attendees-scan,
body#home-welcome {
  background-image: url('../images/card_background.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  color: white;

  .koulen-regular {
    font-family: "Koulen", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.5;
  }

  main {
    background-color: #fff0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

body#attendees-scanmobile {
  background-image: url('../images/phone_id_background.png');
  overflow-y: hidden ! important;
  overflow-x: hidden ! important;
  background-color: #f8f8f8;
  /*background-size: cover;*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  color: #20409a;

  .moulhand-regular {
    font-family: "Kh Ang MoulHand 2", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    color: #20409a;
    font-size: 15px;
  }

  .battambang-regular {
    font-size: 15px;
    font-family: "Content S1", system-ui;
    font-weight: 400;
    font-style: normal;
    color: #20409a;
  }

  .metropolis {
    font-size: 15px;
    font-family: "Metropolis", Verdana, serif;
    font-weight: 400;
    color: #20409a !important;
  }

  main {
    background-color: #fff0;

    #photo {
      padding-top: 215px;
    }
  }
}

div.pagination {
  * {
    padding: 1px 7px;
    margin: 0 2px;
    border: transparent;
    border-radius: 0.325rem !important;
    color: var(--bs-primary);
    background-color: rgba(var(--bs-primary-rgb), 0.1);

    &.current {
      color: white;
      background-color: var(--bs-primary);
    }
  }
}

body#attendees-card {
  div.page-content-wrapper {
    background-image: url('../images/id_background.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    color: #20409a;

    display: flex;
    align-items: center;
    justify-content: center;

    .koulen-regular {
      font-family: "Koulen", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: #20409a;
      line-height: 1.5;

      span {
        font-family: "Roboto", sans-serif;
      }
    }
  }

  @media print {
    @page {
      size: landscape
    }

    body,
    main,
    .page-content,
    nav.navbar,
    div.back-top {
      visibility: hidden;
    }

    section {
      padding-top: 105px;
    }

    .page-content-wrapper {
      background: url('../images/id_background.png');
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      padding: 0px;
      margin: 0px;
      width: 100vw;
      height: 100vh;
      page-break-after: always;

      .rounded-3 {
        margin-top: 100px;
      }

      .moul-regular {
        font-size: 15px;
      }

      #president-name {
        margin-top: -35px;
      }

    }
  }
}

body#students-print_card,
body#students-print_undergraduate_card,
body#students-print_master_card,
body#students-print_phd_card {
  .font-extra-bold {
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  .table-wrapper.associate-degree {
    background-image: url('../images/bachelor_degree.png');
  }

  .table-wrapper.bachelor-degree {
    background-image: url('../images/bachelor_degree.png');
  }

  .table-wrapper.master-degree {
    background-image: url('../images/master_degree.png');
  }

  .table-wrapper.doctor-degree {
    background-image: url('../images/doctor_degree.png');
  }

  .table-wrapper {
    margin: 0 auto;
    margin-top: 5px;
    width: 163mm;
    height: 103mm;
    background-repeat: no-repeat;
    background-size: contain;

    table {
      width: 163mm;
      height: 103mm;


      tr:first-child {
        height: 63px;
      }

      tr:last-child {
        height: 153px;
      }

      tr>td:first-child {
        width: 6%;
      }

      tr>td:nth-child(2) {
        width: 22%;

        img {
          width: 125px;
          height: auto;
        }
      }

      tr>td:nth-child(3) {
        width: 19%;
      }

      tr>td:nth-child(4) {
        width: 40%;
      }

      tr>td:last-child {
        width: 5%;
      }

      tr.khmer-row {
        height: 25px;
      }

      tr.enlish-row {
        height: 15px;
      }
    }
  }
}

.qr-code {
  table {
    border-width: 0;
    border-style: none;
    border-color: #0000ff;
    border-collapse: collapse;
    margin-top: 0px;
  }

  td {
    border-left: solid 3.5px #000;
    padding: 0;
    margin: 0;
    width: 0px;
    height: 3px;
  }

  td.black {
    border-color: #000;
  }

  td.white {
    border-color: #fff;
  }
}
